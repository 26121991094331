import readingStore from "./Stores/readingStore";
import satReadingStore from "./Stores/satReadingStore";
import writingStore from "./Stores/writingStore";

class RootStore {
  constructor() {
    this.readingStore = readingStore;
    this.satReadingStore =satReadingStore;
    this.writingStore = writingStore;
  }
}

const rootStore = new RootStore();
export default rootStore;
