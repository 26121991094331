import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <div class="flex h-[calc(100vh-80px)] items-center justify-center p-5 bg-white w-full">
      <div class="text-center">
        <div class="inline-flex rounded-full bg-yellow-100 p-4">
          <div class="rounded-full stroke-yellow-600 bg-yellow-200 p-4">
            <svg
              class="w-16 h-16"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.0002 9.33337V14M14.0002 18.6667H14.0118M25.6668 14C25.6668 20.4434 20.4435 25.6667 14.0002 25.6667C7.55684 25.6667 2.3335 20.4434 2.3335 14C2.3335 7.55672 7.55684 2.33337 14.0002 2.33337C20.4435 2.33337 25.6668 7.55672 25.6668 14Z"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
        </div>
        <h1 class="mt-5 text-[36px] font-bold text-slate-800 lg:text-[50px]">
          404 - Page not found
        </h1>
        <p class="text-slate-600 mt-5 lg:text-lg">
          The page you are looking for doesn't exist or <br />
          has been removed.
        </p>
        <button className="mt-5">
          <Link
            className="relative inline-block text-sm font-medium text-[#FF6A3D] group active:text-orange-500 focus:outline-none focus:ring"
            to="/"
          >
            <span className="absolute inset-0 transition-transform translate-x-0.5 translate-y-0.5 bg-[#FF6A3D] group-hover:translate-y-0 group-hover:translate-x-0"></span>
            <span className="relative block px-8 text-white py-3 bg-[#1A2238] border border-current">
              Go Home
            </span>
          </Link>
        </button>
      </div>
    </div>
  );
};

export default NotFoundPage;
