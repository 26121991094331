import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReadingIcon from "../../assets/icons/reading.png";
import ReadingActiveIcon from "../../assets/icons/reading_active.png";
import hamburgerIcon from "../../assets/icons/hamburger.png";
import ListeningIcon from "../../assets/icons/listening.png";
import ListeningActiveIcon from "../../assets/icons/listening_active.png";
import GenerativeAiIcon from "../../assets/icons/OpenAi.svg";
import GenerativeAiActiveIcon from "../../assets/icons/OpenAiSelect.svg";
import generativeAIStore from "../../Services/Stores/generativeAiStore";
import WritingIcon from "../../assets/icons/writing.svg";
import WritingActiveIcon from "../../assets/icons/writingactive.svg";
import newWindow from "../../assets/icons/new-window.svg";
import satReadingStore from "../../Services/Stores/satReadingStore";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";

const SidebarV1 = ({ open, setOpen }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const Store = generativeAIStore;
  const {setModuleId,currentSectionName} = satReadingStore
  const redirectToAnotherRoute = (route) => {
    navigate(route);
  };

  const isRouteActive = (baseRoute) => location.pathname.startsWith(baseRoute);

  const openWritingWindow = (route) => {
    window.open(route);
  };

  const Menus = [
    {
      title: "Writing",
      src: WritingIcon,
      active: WritingActiveIcon,
      redirectTo: "https://ielts.cms.cambri.ai/login",
    },
    {
      title: "Reading",
      src: ReadingIcon,
      active: ReadingActiveIcon,
      redirectTo: "/dashboard",
    },
    {
      title: "WritingV2",
      src: WritingIcon,
      active: WritingActiveIcon,
      redirectTo: "/writing",
    },
    {
      title: "Listening",
      src: ListeningIcon,
      active: ListeningActiveIcon,
      redirectTo: "/listening",
    },
    {
      title: "Generative AI",
      src: GenerativeAiIcon,
      active: GenerativeAiActiveIcon,
      redirectTo: "/generativeAI",
    },
  ];

  const SatMenus = [
    {
      title: "Reading & Writing",
      src: ReadingIcon,
      active: ReadingActiveIcon,
      redirectTo: "/satReading",
      isActive:currentSectionName === "ReadingAndWriting"
    },
    {
      title: "Maths",
      src: ListeningIcon,
      active: ListeningActiveIcon,
      redirectTo: "/satMaths",
      isActive:currentSectionName === "Mathematics"
    },
  ];

  return (
    <div className="flex border-r border-r-[#E3E5E9] pb-16">
      <div
        className={`pt-2 ${
          open ? "w-72" : "w-14"
        } bg-white h-screen pb-5 relative duration-300`}
      >
        <div
          className={`${open && "gap-x-4 px-4 pt-2"} flex items-center px-2`}
        >
          <img
            className={`cursor-pointer bg-[#FFEAEA] rounded-full ${
              !open && "rotate-180"
            }`}
            onClick={() => setOpen(!open)}
            src={hamburgerIcon}
            alt="hamburger_Icon"
          />
          <h1
            className={`text-[black] origin-left font-medium text-xl duration-200 ${
              !open && "scale-0"
            }`}
          >
            CMS
          </h1>
        </div>
        <ul className="pt-10">
          <span
            className={` origin-left text-xl underline duration-200 text-[#E31837] font-bold p-4`}
          >
            {!open ? <span className="  rounded-full ">I</span> : "IELTS"}
          </span>
          {Menus.map((Menu, index) => (
            <button
              className="w-full"
              key={Menu.title}
              onClick={() => {
                Menu.title === "Writing"
                  ? openWritingWindow(Menu.redirectTo)
                  : redirectToAnotherRoute(Menu.redirectTo);
                Store.setPassageValue("");
                Store.questionTypes.map((item) => {
                  return (item.responseValue = "");
                });
              }}
            >
              <li
                title={Menu.title}
                className={`flex p-4 cursor-pointer text-gray-300 text-sm items-center ${
                  open ? "gap-x-4" : "gap-x-0"
                } mt-2} ${isRouteActive(Menu.redirectTo) && "bg-[#FFEAEA]"}`}
              >
                <img
                  className="w-5 h-5"
                  src={
                    location.pathname === Menu.redirectTo
                      ? Menu.active
                      : Menu.src
                  }
                  alt="redirect"
                />
                <span
                  className={`${!open && "hidden"}  ${
                    location.pathname === Menu.redirectTo
                      ? "text-[#E31837] font-medium"
                      : "text-[#737E93]"
                  } origin-left text-xl duration-200 `}
                >
                  {Menu.title}
                </span>
                {Menu.title === "Writing" ? (
                  <span>
                    <img src={newWindow} alt="NewWinodw" />
                  </span>
                ) : (
                  ""
                )}
              </li>
            </button>
          ))}
          <span
            className={` origin-left text-xl underline duration-200 text-blue-800 font-bold p-4 `}
          >
            {!open ? <span className="  rounded-full ">S</span> : "SAT"}
          </span>
          {SatMenus.map((menu, index) => {
            console.log(menu.isActive,currentSectionName,"Active Pane")
            return (
              <div>
                <button
                  onClick={() => {
                    redirectToAnotherRoute(menu.redirectTo);
                    setModuleId("")
                }}
                  className={`text-[#737E93] flex p-4 cursor-pointer text-xl my-2  ${
                    location.pathname === menu.redirectTo? "text-blue-800 font-medium bg-blue-100"
                      : "text-[#737E93]"
                  } w-full ${
                    !open && "hidden text-xl origin-left duration-200 "
                  }`}
                >
                  {menu.title}
                </button>
              </div>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
export default observer(SidebarV1);
