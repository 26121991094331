import { observer } from "mobx-react-lite";
import React from "react";
import CustomButton from "../Common/CustomButton";
import Modal from "../Common/Modal";
import readingStore from "../../Services/Stores/readingStore";
import satReadingStore from "../../Services/Stores/satReadingStore";

const QuestionTypeModal = observer(
  ({ isOpen, onClose, setQuestionType, onQuestionTypeChange,data }) => {
    return (
      <div>
        <Modal isOpen={isOpen} onClose={onClose}>
          <div className="flex flex-col">
            <h1 className="text-2xl font-bold mb-8">
              Select Question Category
            </h1>
            <select
              className="border border-[#E3E5E9] py-4 px-2 rounded-lg focus:outline-none"
              onChange={(e) =>{
                console.log(e.target.value,"VALUE")
                setQuestionType(e.target.value);
                satReadingStore.savePersistedData()
              } }
            >
              <option value={""}>Select</option>
              {data &&
                data.length > 0 &&
                data.map((item) => (
                  <option
                    // disabled={item.name === "Matching Features"}
                    key={item._id}
                    value={item.name}
                    name={item.name}
                  >
                    {item.name}
                  </option>
                ))}
            </select>
            <div className="flex justify-between mt-8">
              <CustomButton
                // disabled={!questionType || questionType === ""}
                classes={"bg-[#E31837] text-white rounded-3xl px-4 py-1 my-4"}
                label="Ok"
                handleClick={onQuestionTypeChange}
              />
              <CustomButton
                classes={
                  "border border-[#E31837] text-[#E31837] rounded-3xl px-4 py-1 my-4"
                }
                label="Close"
                handleClick={onClose}
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
);

export default QuestionTypeModal;
