import React, { useEffect, useState, Fragment, useRef } from "react";
import CustomButton from "../Components/Common/CustomButton";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { observer } from "mobx-react-lite";
import readingStore from "../Services/Stores/readingStore";
import { genericHeaders, serviceUrl } from "../utils/apiConfig";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin5Line } from "react-icons/ri";
import CustomToolTip from "../Components/Common/CustomToolTip";
import { RotatingLines } from "react-loader-spinner";
import { toast } from "react-toastify";
import { BsPatchExclamationFill } from "react-icons/bs";
import ErrorPage from "../Components/Common/ErrorPage";
import { Dialog, Transition } from "@headlessui/react";
import satReadingStore from "../Services/Stores/satReadingStore";
import Modal from "../Components/Common/Modal";

const ReadingMain = observer(() => {
  const navigate = useNavigate();
  const { selectedSectionId, sections } = readingStore;
  const { currentClickedQuestion, setCurrentClickedQuestion } = satReadingStore;
  const fileInputRef = useRef(null);

  const [questions, setQuestions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorData, setErrorData] = useState({
    statusCode: "",
    message: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState({});

  const fetchSections = async () => {
    try {
      setLoading(true);
      const url = `${serviceUrl}/api/section/getAll`;
      const response = await axios.get(url, { headers: genericHeaders() });
      readingStore.setSections(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const fetchAllQuestions = async () => {
    if (selectedSectionId) {
      setLoading(true);
      try {
        const url = `${serviceUrl}/api/questions/getAll`;
        const response = await axios.get(url, { headers: genericHeaders() });
        if (response.status === 200) {
          setLoading(false);
        }
        const data = response.data.filter(
          (item) => item.sectionId === selectedSectionId
        );
        setQuestions(data);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
        setError(true);
        setErrorData({
          statusCode: error.response.data.status,
          message: error.response.data.message,
        });
        console.log(error.response, "sdfg");
      }
    }
  };

  useEffect(() => {
    fetchAllQuestions();
  }, [selectedSectionId]);

  useEffect(() => {
    fetchSections();
  }, []);

  const deleteQuestion = async () => {
    setLoading(true);
    try {
      const url = `${serviceUrl}/api/questions/delete?paragraphId=${currentClickedQuestion?.["_id"]}`;
      const response = await axios.delete(url, { headers: genericHeaders() });
      let tempQuestions = questions.filter(
        (item) => item._id !== currentClickedQuestion?.["_id"]
      );
      setLoading(false);
      setQuestions(tempQuestions);
      fetchAllQuestions();
      setCurrentClickedQuestion({});
      toast.success("Deleted Successfully");
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleEdit = (id) => {
    navigate(`/reading/${id}`);
  };

  const handleDelete = (id) => {
    deleteQuestion(id);
  };
  const openDialogue = () => {
    setIsOpen(true);
  };

  const closeDialogue = () => {
    setIsOpen(false);
  };

  const handleDocsChange = async (evt) => {
    setFile(evt.target.files[0]);
    setIsLoading(true);
    try {
      const url = `${serviceUrl}/api/excelimport/readingexcelimport?sectionId=${selectedSectionId}`;
      const formData = new FormData();
      formData.append("excelFile", evt.target.files[0]);
      const res = await axios.post(url, formData, {
        headers: {
          ...genericHeaders(),
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.status === 200) {
        toast.success(res?.data);
        setIsLoading(false);
        fetchAllQuestions();
        setFile({});
        setIsModalOpen(false);
      }
    } catch (err) {
      setIsLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };

  return (
    <div className="w-full mx-auto min-h-screen">
      {loading ? (
        <div className="flex justify-center items-center min-h-screen">
          <RotatingLines
            visible={true}
            strokeColor="red"
            height="96"
            width="96"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : error ? (
        <ErrorPage
          statusCode={errorData.statusCode}
          message={errorData.message}
        />
      ) : (
        <div className="px-10">
          <div className="flex items-center justify-between w-full mt-20">
            <div className="flex items-center">
              <label className="mr-2 text-base whitespace-nowrap">
                Select Section:
              </label>
              <select
                className="py-2 px-4 border bg-white border-gray-200 rounded-lg text-base focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                onChange={(e) => {
                  let sec = "";
                  if (e.target.value !== "" || e.target.value) {
                    sec = sections.find((item) => item._id === e.target.value);
                  }
                  readingStore.setSelectedSectionName(sec.name);
                  readingStore.setSelectedSectionId(e.target.value);
                  readingStore.savePersistedData();
                }}
                value={selectedSectionId}
              >
                <option value={""}>Select</option>
                {sections &&
                  sections.length > 0 &&
                  sections.map((item) => (
                    <option key={item._id} value={item._id} name={item.name}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="w-full flex justify-end gap-2">
              <CustomButton
                disabled={!selectedSectionId || selectedSectionId === ""}
                classes={"bg-[#E31837] text-white"}
                label="Import Template"
                handleClick={() => setIsModalOpen(true)}
              />
              <CustomButton
                disabled={!selectedSectionId || selectedSectionId === ""}
                classes={"bg-[#E31837] text-white"}
                label="Create Passage"
                handleClick={() => navigate("/reading")}
              />
            </div>
          </div>
          {selectedSectionId && selectedSectionId !== "" ? (
            <div>
              <table className="border-collapse w-full mt-4">
                <thead>
                  <tr className="bg-[#F8F9FA] text-[#737E93] text-sm font-normal">
                    <th className="border py-2 px-4">S.No</th>
                    <th className="border py-2 px-4">Title</th>
                    <th className="border py-2 px-4">Paragraph</th>
                    <th className="border py-2 px-4">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {questions && questions.length > 0 ? (
                    questions.map((item, index) => (
                      <tr key={item.id}>
                        <td className="border py-2 px-4 text-center">
                          {index + 1}
                        </td>
                        <td className="border py-2 px-4 text-center w-1/4">
                          {item.title}
                        </td>
                        <td className="border py-2 px-4 text-center">
                          <p className="line-clamp-2 overflow-hidden">
                            {item.content}
                          </p>
                        </td>
                        <td className="border py-2 px-4 text-center w-[14%] whitespace-nowrap">
                          <div className="flex justify-center">
                            <div>
                              <button
                                data-tooltip-id="edit-tooltip"
                                className="mr-2"
                                onClick={() => handleEdit(item._id)}
                              >
                                <BiEdit className="text-xl" />
                              </button>
                              <CustomToolTip
                                content={"Edit"}
                                id={"edit-tooltip"}
                                place={"bottom"}
                              />
                            </div>
                            <div>
                              <button
                                data-tooltip-id="delete-tooltip"
                                onClick={() => {
                                  setCurrentClickedQuestion(item);
                                  openDialogue();
                                }}
                              >
                                <RiDeleteBin5Line className="text-red-500 text-xl" />
                              </button>
                              <CustomToolTip
                                content={"Delete"}
                                id={"delete-tooltip"}
                                place={"bottom"}
                              />
                              <Transition appear show={isOpen} as={Fragment}>
                                <Dialog
                                  as="div"
                                  className="relative z-10"
                                  onClose={closeDialogue}
                                >
                                  <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <div className="fixed inset-0" />
                                  </Transition.Child>

                                  <div className="fixed inset-0 overflow-y-auto">
                                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                                      <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 scale-95"
                                        enterTo="opacity-100 scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 scale-100"
                                        leaveTo="opacity-0 scale-95"
                                      >
                                        <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                          <Dialog.Title
                                            as="h3"
                                            className="text-lg font-medium leading-6 text-gray-900"
                                          >
                                            Delete
                                          </Dialog.Title>
                                          <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                              Are you sure you want to delete
                                              the data?
                                            </p>
                                          </div>

                                          <div className="mt-4">
                                            <button
                                              type="button"
                                              className="inline-flex justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
                                              onClick={() => {
                                                closeDialogue();
                                                setCurrentClickedQuestion({});
                                              }}
                                            >
                                              Cancel
                                            </button>
                                            <button
                                              type="button"
                                              className="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 ml-6"
                                              onClick={() => {
                                                deleteQuestion();
                                                closeDialogue();
                                              }}
                                            >
                                              Delete
                                            </button>
                                          </div>
                                        </Dialog.Panel>
                                      </Transition.Child>
                                    </div>
                                  </div>
                                </Dialog>
                              </Transition>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="w-full">
                      <td colSpan="4" className="border py-2 px-4 text-center">
                        <div className="flex justify-center flex-col w-full items-center text-blue-950">
                          <BsPatchExclamationFill className="mt-3 text-5xl" />
                          <p className="my-4 text-lg text-gray-600">
                            No Passages have been created yet,click on create
                            passage to create a new passage.
                          </p>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <p className="flex justify-center mt-14 text-xl font-medium">
              Select a section to view the data
            </p>
          )}
        </div>
      )}
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setFile({});
          setIsModalOpen(false);
        }}
      >
        <div className="flex flex-col">
          <Link
            className="underline"
            to="https://ielts-app.s3.amazonaws.com/excel_files/Reading_Bulk_Import.xlsx"
          >
            Download Sample Template
          </Link>
          <label disabled={isLoading} className="upload-label mt-8">
            <input
              type="file"
              onChange={handleDocsChange}
              ref={fileInputRef}
              style={{ display: "none" }}
              className="bg-white"
              disabled={isLoading}
            />
            <button
              disabled={isLoading}
              className="bg-green-500 text-white py-2 px-4 rounded cursor-pointer hover:bg-green-600"
              onClick={() => fileInputRef.current.click()}
            >
              {isLoading ? "Uploading ..." : "Upload Template"}
            </button>
          </label>
          {file?.name && (
            <span className="mt-4">Selected File: {file?.name}</span>
          )}
        </div>
        <div className="flex justify-end mt-6">
          <CustomButton
            label={"Close"}
            handleClick={() => {
              setFile({});
              setIsModalOpen(false);
            }}
          />
        </div>
      </Modal>
    </div>
  );
});

export default ReadingMain;
