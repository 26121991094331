
import { makeAutoObservable } from "mobx";

class SatReadingStore {
  questionType = "";
  modules={};
  loading=false;
  isGetAllQuestionsLoaded=false;
  currentSectionId="";
  currentModuleId="";
  currentModuleName="";
  currentSectionName = ""
  sectionsData = [];
  questionCategory = [];
  currentModuleQuestions =[];
  currentClickedQuestion={};
  currentPage=1;
  


  constructor() {
    makeAutoObservable(this);
   this.loadPersistedData();
  }

  setCurrentPage = (page) => {
    this.currentPage = page
  };

  setCurrentClickedQuestion = (item) => {
    this.currentClickedQuestion = item;
  }
  setQuestionType = (question) => {
    this.questionType = question;
  };
  setModules = (moduleList) => {
    this.modules = moduleList
  }
  setIsLoading = (bool) => {
    this.loading = bool
  }
  setIsGetAllQuestionsLoaded = (bool) => {
    this.isGetAllQuestionsLoaded = bool
  }
  setSectionId = (sectionId) => {
    this.currentSectionId = sectionId;
  }
  setModuleId = (moduleId) => {
    this.currentModuleId = moduleId;
  }
  setModuleName = (moduleName) => {
    this.currentModuleName = moduleName;
  }
  setSectionName = (sectionName) => {
this.currentSectionName = sectionName
  }
  setSectionsData = (sections) => {
    this.sectionsData = sections;
  }
  setQuestionTypes =(questionTypes) => {
    this.questionCategory = questionTypes
  }
  setCurrentModule =(moduleQns) => {
    this.currentModuleQuestions = moduleQns
  }

  savePersistedData =() => {
    localStorage.setItem("selectedModule",JSON.stringify({
        sectionId:this.currentSectionId,
        moduleId:this.currentModuleId,
        moduleName:this.currentModuleName,
        sectionName:this.currentSectionName,
        questionsCat:this.questionCategory,
        questionType:this.questionType

    }))
  }
  loadPersistedData(){
    let  data= JSON.parse(localStorage.getItem('selectedModule'));
    if(!data){return;}
    this.setSectionId(data.sectionId);          
    this.setModuleName(data.moduleName);  
    this.setSectionName(data.sectionName);        
    this.setModuleId(data.moduleId);
    this.setQuestionTypes(data.questionsCat);
    this.setQuestionType(data.questionType)
  }

//   clearStore =() => {
//     this.questionType=""
//     this.modules ={}
//   this.currentModuleId =""
//   this.currentModuleName ="";
//   this.currentSectionId="";
//   this.currentSectionName="";   
//   this.currentModuleQuestions=[];
//   this.questionCategory =[]
//   this.currentModuleQuestions =[]
//   }
}

const satReadingStore = new SatReadingStore();

export default satReadingStore;

