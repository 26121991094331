import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import satReadingStore from "../Services/Stores/satReadingStore";
import { IoMdArrowBack } from "react-icons/io";
import { toJS } from "mobx";
import "katex/dist/katex.min.css";
import TeX from "@matejmazur/react-katex";

const ViewMathQuestion = () => {
  const { currentModuleQuestions,currentSectionId ,setSectionId} = satReadingStore;
  const { id } = useParams();
  const navigate = useNavigate();
  // routeParams.id will contain the value of the :id parameter from the URL


  const renderImageContent =(item,index) =>{
    //return <p key={index} className="mx-2">{item}</p>
    const parts = item.split(/\[\{.*?\}\]/g);

 // Extract the URLs from the parts that match the pattern
 const urls = item.match(/\[\{.*?\}\]/g)?.map(url => url.slice(2, -2)) || [];

 return (
    <div>
      {parts.map((part, index) => (
        <React.Fragment key={index}>
          {part}
          {urls[index] && <img src={urls[index]} alt="Dynamic" />}
        </React.Fragment>
      ))}
    </div>
 );
  }


  const renderHtmlWithTeX = () => {
   
    let tempVariable  = currentModuleQuestions.find((item,index) => item["_id"] === id);
    let matchesText = tempVariable.question.split("$~");

    return matchesText.map((item, index) => {
      if (index % 2 === 1) {
        let tempString = String.raw`${item}`;
        let res = JSON.stringify(tempString)
        
        return <TeX key={index}>{item}</TeX>;
      }
      return renderImageContent(item,index)
    });
  };
let instructions = [
  "If you find more than one correct answer, enter only one answer.",
  "You can enter up to 5 characters for a positive answer and up to 6 characters (including the negative sign) for a negative answer.",
  "If your answer is a fraction that doesn't fit in the provided space, enter the decimal equivalent.",
  "If your answer is a decimal that doesn't fit in the provided space, enter it by truncating or rounding at the fourth digit.",
  "If your answer is a mixed number (such as 3 1/2 ), enter it as an improper fraction (7/2) or its decimal equivalent (3.5).",
  "Don't enter symbols such as a percent sign, comma, or dollar sign"
];


  return (
    <div className="mt-16 px-6">
      <button
        className="hover:bg-slate-500 hover:text-white rounded-md flex items-center bg-gray-300 py-2 px-6 mb-4"
        onClick={() =>{navigate("/satMaths")
        setSectionId(currentSectionId)} }
      >
        <IoMdArrowBack /> <span className="ml-1">Back</span>
      </button>
      {/* <div></div> */}
      <div className="flex my-4">{renderHtmlWithTeX()}</div>
      
    </div>
  );
};

export default ViewMathQuestion;
