import React, { useEffect, useRef, useState, Fragment } from "react";
import CustomButton from "../Components/Common/CustomButton";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { observer } from "mobx-react-lite";
import listeningStore from "../Services/Stores/listeningStore";
import { genericHeaders, serviceUrl } from "../utils/apiConfig";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin5Line } from "react-icons/ri";
import CustomToolTip from "../Components/Common/CustomToolTip";
import { RotatingLines } from "react-loader-spinner";
import { toast } from "react-toastify";
import { BsPatchExclamationFill } from "react-icons/bs";
import ErrorPage from "../Components/Common/ErrorPage";
import { Dialog, Transition } from "@headlessui/react";
import satReadingStore from "../Services/Stores/satReadingStore";

const ListeningMain = observer(() => {
  const navigate = useNavigate();
  const { selectedSectionId, sections } = listeningStore;
  const audioRef = useRef(null);
  const {currentClickedQuestion,setCurrentClickedQuestion} = satReadingStore

  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
 
  

  const fetchSections = async () => {
    try {
      const url = `${serviceUrl}/api/listeningsection/getall`;
      const response = await axios.get(url, { headers: genericHeaders() });
      listeningStore.setSections(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchAllQuestions = async () => {
    if (selectedSectionId) {
      setLoading(true);
      try {
        const url = `${serviceUrl}/api/listeningquestions/getall`;
        const response = await axios.get(url, { headers: genericHeaders() });
        if (response.status === 200) {
          setLoading(false);
        }
        const data = response.data.filter(
          (item) => item.sectionId === selectedSectionId
        );
        setQuestions(data);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
        setError(true);
      }
    }
  };

  useEffect(() => {
    fetchAllQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSectionId]);

  useEffect(() => {
    fetchSections();
  }, []);

  const deleteQuestion = async () => {
    try {
      const url = `${serviceUrl}/api/listeningaudio/delete?listeningAudioId=${currentClickedQuestion["_id"]}`;
      const response = await axios.delete(url, { headers: genericHeaders() });
      // let tempQuestions = questions.filter(
      //   (item) => item._id !== currentClickedQuestion?.["_id"]
      // );
      // setQuestions(tempQuestions);
      fetchAllQuestions();
      toast.success("Deleted Successfully");
      setCurrentClickedQuestion({})
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const deleteListeningAudio = async (id) => {
    try {
      const url = `${serviceUrl}/api/listeningaudio/delete?listeningAudioId=${id}`;
      const response = await axios.delete(url, { headers: genericHeaders() });
      if (response.status === 200) {
        toast.success("Deleted Successfully");
        fetchAllQuestions();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleEdit = (id) => {
    navigate(`/listening/${id}`);
  };

  const openDialogue = () => {
    setIsOpen(true);
  };

  const closeDialogue = () => {
    setIsOpen(false);
  };

  return (
    <div className="w-[80%] mx-auto min-h-screen">
      {loading ? (
        <div className="flex justify-center items-center min-h-screen">
          <RotatingLines
            visible={true}
            strokeColor="red"
            height="96"
            width="96"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : error ? (
        <ErrorPage />
      ) : (
        <div>
          <div className="flex items-center justify-between w-full mt-20">
            <div className="flex items-center">
              <label className="mr-2 text-base whitespace-nowrap">
                Select Section:
              </label>
              <select
                className="py-2 px-4 border bg-white border-gray-200 rounded-lg text-base focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                onChange={(e) => {
                  let sec = "";
                  if (e.target.value !== "" || e.target.value) {
                    sec = sections.find((item) => item._id === e.target.value);
                  }
                  listeningStore.setSelectedSectionName(sec.name);
                  listeningStore.setSelectedSectionId(e.target.value);
                  listeningStore.savePersistedData();
                }}
                value={selectedSectionId}
              >
                <option value={""}>Select</option>
                {sections &&
                  sections.length > 0 &&
                  sections.map((item) => (
                    <option key={item._id} value={item._id} name={item.name}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="w-full flex justify-end">
              <CustomButton
                disabled={!selectedSectionId || selectedSectionId === ""}
                classes={"bg-[#E31837] text-white"}
                label="Add Audio"
                handleClick={() => navigate("/createlisteningpassage")}
              />
            </div>
          </div>
          {selectedSectionId && selectedSectionId !== "" ? (
            <div>
              <table className="border-collapse w-full mt-4">
                <thead>
                  <tr className="bg-[#F8F9FA] text-[#737E93] text-sm font-normal">
                    <th className="border py-2 px-4">S.No</th>
                    <th className="border py-2 px-4">Title</th>
                    <th className="border py-2 px-4">Audio</th>
                    <th className="border py-2 px-4">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {questions && questions.length > 0 ? (
                    questions.map((item, index) => (
                      <tr key={item.id}>
                        <td className="border py-2 px-4 text-center">
                          {index + 1}
                        </td>
                        <td className="border py-2 px-4 text-center w-1/4">
                          {item.title}
                        </td>
                        <td className="border py-2 px-4 text-center">
                          <p className="line-clamp-3 overflow-hidden">
                            {item &&
                              item.audioFiles &&
                              item.audioFiles.length > 0 &&
                              item.audioFiles[0].name}
                            {/* <audio ref={audioRef} controls className="mt-2">
                              <source
                                src={item.audioFiles[0].resourceName}
                                type="audio/mp3"
                              />
                              Your browser does not support the audio element.
                            </audio> */}
                            <br />
                          </p>
                        </td>
                        <td className="border py-2 px-4 text-center w-[14%] whitespace-nowrap">
                          <div className="flex justify-center">
                            <div>
                              <button
                                data-tooltip-id="edit-tooltip"
                                className="mr-2"
                                onClick={() => handleEdit(item._id)}
                              >
                                <BiEdit className="text-xl" />
                              </button>
                              <CustomToolTip
                                content={"Edit"}
                                id={"edit-tooltip"}
                                place={"bottom"}
                              />
                            </div>
                            <div>
                              <button
                                data-tooltip-id="delete-tooltip"
                                onClick={() => {
                                  setCurrentClickedQuestion(item)
                                  openDialogue()}}
                              >
                                <RiDeleteBin5Line className="text-red-500 text-xl" />
                              </button>
                              <CustomToolTip
                                content={"Delete"}
                                id={"delete-tooltip"}
                                place={"bottom"}
                              />
                              <Transition appear show={isOpen} as={Fragment}>
                                <Dialog
                                  as="div"
                                  className="relative z-10"
                                  onClose={closeDialogue}
                                >
                                  <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <div className="fixed inset-0" />
                                  </Transition.Child>

                                  <div className="fixed inset-0 overflow-y-auto">
                                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                                      <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 scale-95"
                                        enterTo="opacity-100 scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 scale-100"
                                        leaveTo="opacity-0 scale-95"
                                      >
                                        <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                          <Dialog.Title
                                            as="h3"
                                            className="text-lg font-medium leading-6 text-gray-900"
                                          >
                                            Delete
                                          </Dialog.Title>
                                          <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                              Are you sure you want to delete
                                              the data?
                                            </p>
                                          </div>

                                          <div className="mt-4">
                                          <button
                                              type="button"
                                              className="inline-flex justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
                                              onClick={() => {
                                                setCurrentClickedQuestion({})
                                                closeDialogue();
                                              }}
                                            >
                                             Cancel
                                            </button>
                                            <button
                                              type="button"
                                              className="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 ml-6"
                                              onClick={() => {                                               
                                                deleteQuestion()
                                                closeDialogue();
                                              }}
                                            >
                                             Delete
                                            </button>
                                          </div>
                                        </Dialog.Panel>
                                      </Transition.Child>
                                    </div>
                                  </div>
                                </Dialog>
                              </Transition>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="w-full">
                      <td colSpan="4" className="border py-2 px-4 text-center">
                        <div className="flex justify-center flex-col w-full items-center text-blue-950">
                          <BsPatchExclamationFill className="mt-3 text-5xl" />
                          <p className="my-4 text-lg text-gray-600">
                            No audios have been created yet,click on add audio
                            to create a new audio.
                          </p>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <p className="flex justify-center mt-14 text-xl font-medium">
              Select a section to view the data
            </p>
          )}
        </div>
      )}
    </div>
  );
});

export default ListeningMain;
