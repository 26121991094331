import React, { useEffect } from "react";
import { upload_file_to_s3 } from "../Common/FileUpload";
import { GrView } from "react-icons/gr";

const UploadImage = ({
  item,
  questionTitleImages,
  setQuestionTitleImages,
  indexTitleImage,
}) => {
  const key = Object.keys(item)[0];
  const fileName = (uploadedImage) => {
    console.log(uploadedImage,"uploadedImageRFGYUKP,L")
    let name = uploadedImage.replace("https://cambri.s3-ap-south-1.amazonaws.com/SAT/","");
    return name
  }

  const handleFileChange = async (event) => {
    let public_url;
    try {
      const fileName = event.target.files[0];
      const s3path = await upload_file_to_s3(
        "SAT",
        fileName,
        `u_${new Date().getTime()}_sat`
      );
      public_url = s3path.location;
      if (public_url) {
        const updatedImages = questionTitleImages.map((img) => {
          if (key === Object.keys(img)[0]) {
            return { [key]: public_url, isView: true, isEdit: false };
          }
          return img;
        });
        setQuestionTitleImages(updatedImages);
      }
    } catch (e) {
      console.log(e, "ERROR");
    }
  };
console.log(item,"ITEMMMMMM IN RENDER UPLOAD IMAGES")
  return (
    <div>
      <div className="flex mt-1 mb-3 w-full items-center px-3 mx-4">
        <label>{key}</label>
        <input type="file" onChange={handleFileChange} className="mx-4"/>
        {item.isView && (
          <div
          className="mx-4 flex"
            onClick={() => {
              let viewImages = questionTitleImages.map((img) => {
                if (key === Object.keys(img)[0]) {
                  return { ...img, isView: true, isEdit: !img.isEdit };
                }
                return img;
              });
              setQuestionTitleImages(viewImages);
            }}
          >
              <span className="mx-2">{fileName(item[key])}</span>
            {item.isEdit ? (
              <button className="border border-[#184ee3] text-[#184ee3]  items-center rounded-full my-2 px-4 py-1 mr-4">
                Close
              </button>
            ) : (
              <GrView className="text-blue-500 text-xl cursor-pointer items-center" />
            )}
          </div>
        )}
      </div>
      <div>{item.isEdit && <img src={item[key]} alt="cms" />}</div>
    </div>
  );
};

export default UploadImage;
