import { makeAutoObservable } from "mobx";

class WritingStore {
  selectedSectionId = null;
  selectedSectionName = "";
  editMode = false;
  questionsCategory = [];
  multipleSingle = false;
  sections = [];
  questionsPartOne = [];
  questionsPartTwo = [];
  selectedSection = {};
  partOnePayload = {  
        name: "",
        taskType: null,
        task: "",
        section: "",
        imageUrl: [
          {
            fileName:"",
            name: "",
            resourceName: "",
            uploadDate: "",
            comment: "",
            extension: ""
          }
        ],
        imageDescription: "",
        chartType: "",     
  }

  partTwoPayload = {  
    name: "",
    taskType: null,
    task: "",
    section: "",    
}

  updatedData={}

  constructor() {
    makeAutoObservable(this);
    this.loadPersistedData();
  }

  setSections(data) {
    this.sections = data;
  }
  setSelectedSection(data) {
    this.selectedSection = data;
  }
setUpdatedData(data){
    this.updatedData = data
}

  setQuestionsPartOne(data) {
    this.questionsPartOne = data;
  }
  setQuestionsPartTwo(data) {
    this.questionsPartTwo = data;
  }

  setEditMode(bool) {
    this.editMode = bool;
  }

  setFormData(newFormData) {
    this.formData = newFormData;
  }

  setSelectedSectionName(name) {
    this.selectedSectionName = name;
  }

  setSelectedSectionId(id) {
    this.selectedSectionId = id;
  }
  setMultipleSingle(value) {
    this.multipleSingle = value;
  }
  setPartOnePayload(data){
    this.partOnePayload = data;
  }
  setPartTwoPayload(data){
    this.partTwoPayload = data;
  }
  // Method to persist data to localStorage
  savePersistedData() {
    console.log("UPDAEDDATA")
      localStorage.setItem("selectedSectionDropDown",JSON.stringify(this.selectedSection))
      localStorage.setItem("updatedData",JSON.stringify(this.updatedData))
  }

  // Method to load persisted data from localStorage
  loadPersistedData() {
    const persistedData = localStorage.getItem("selectedSection");
    if (persistedData) {
      const parsedData = JSON.parse(persistedData);
      this.selectedSectionId = parsedData.id;
      this.selectedSectionName = parsedData.name;
    }
  }
}

const writingStore = new WritingStore();
export default writingStore;
