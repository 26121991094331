import React from "react";

const CustomTextArea = ({
  label,
  handleChange,
  value,
  required,
  textAreaRef,
  handleAddHeading,
  showAddHeadingBtn,
  questionType,
  questionIndex,
}) => {
  return (
    <>
      <label>
        {label}
        {required && <span className="text-[red]">*</span>}
      </label>
      {showAddHeadingBtn && (
        <button
          disabled={questionType !== "Matching Headings"}
          className="ml-4 border border-[red] text-[red] px-4 py-1 rounded-2xl mb-2 bg-white"
          type="button"
          onClick={() => handleAddHeading(questionIndex)}
        >
          Add Heading
        </button>
      )}
      <textarea
        ref={textAreaRef}
        className="peer bg-white min-h-28 text-black h-auto border border-gray-200 w-full rounded bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none"
        id="exampleFormControlTextarea1"
        rows={10}
        cols={10}
        placeholder="Your message"
        onChange={handleChange}
        value={value}
      />
    </>
  );
};

export default CustomTextArea;
