// src/stores/counterStore.js
import { makeAutoObservable } from "mobx";

class GenerativeAiStore {
  passageValue = "";
  questionTypes = [
    {
      id: 1,
      title: "Multiple Choice With One Answer Question",
      prompt:
        "Generate a MULTIPLE CHOICE WITH ONE ANSWER QUESTIONS TEST for the IELTS Academic Reading section: Generate six (6) “Multiple Choice” (with one answer) questions, the correct answer and an explanation for the wrong and correct answers.  The multiple choice questions should adhere to the IELTS Academic Reading Section requirements.",
      submit: "Submit",
      response: "Response",
      responseValue: "",
      loading:false
    },
    {
      id: 2,
      title: "Multiple Choice With More Than One Answer Question",
      prompt:
        "Generate MULTIPLE CHOICE WITH MORE THAN ONE ANSWER QUESTIONS TEST for the IELTS Academic Reading section: Generate six (6) “Multiple Choice” (with more than one answer) questions, the correct answer and an explanation for the wrong and correct answers.  The multiple choice questions should adhere to the IELTS Academic Reading Section requirements.",
      submit: "Submit",
      response: "Response",
      responseValue: "",
      loading:false
    },
    {
      id: 3,
      title: "Note Completion Question",
      prompt:
        "Generate NOTE COMPLETION QUESTIONS TEST for the IELTS Academic Reading section:	Generate six (6) “Note Completion” questions, the correct answer and an explanation for the wrong and correct answers.  The questions should adhere to the IELTS Academic Reading Section requirements",
      submit: "Submit",
      response: "Response",
      responseValue: "",
      loading:false
    },
    {
      id: 4,
      title: "Sentence Completion Question",
      prompt:
        "Generate SENTENCE COMPLETION QUESTIONS TEST for the IELTS Academic Reading section: Generate six (6) “Sentence Completion” questions, the correct answer and an explanation for the wrong and correct answers.  The questions should adhere to the IELTS Academic Reading Section requirements.",
      submit: "Submit",
      response: "Response",
      responseValue: "",
      loading:false
    },
    {
      id: 5,
      title: "Summary Completion Question",
      prompt:
        "Generate SUMMARY COMPLETION QUESTIONS TEST for the IELTS Academic Reading section: Generate six (6) “Summary Completions” questions, the correct answer and an explanation for the wrong and correct answers.  The questions should adhere to the IELTS Academic Reading Section requirements",
      submit: "Submit",
      response: "Response",
      responseValue: "",
      loading:false
    },
    {
      id: 6,
      title: "Table Completion Question",
      prompt:
        "Refer to the attached document that contains an example of the Cambridge’s IELTS Table Completion Test based on a hypothetical passage for the Academic Reading section.It has the standard format of the Table that is used in the Table Completion Test for the IELTS Academic Reading section.Based on the passage given,1.Generate a similar Table Completion Test for the IELTS Academic Reading section.2.Follow the guidelines below to generate the new Table Completion test:3.Introduction and Instructions:3.Introduction and Instructions: • Offer clear instructions for candidates to complete the table using information from the passage, emphasizing the use of no more than three words and/or a number for each answer. 4.Table Structure:• Design the table to compare two or three distinct subjects or categories highlighted in the passage.5.• Row and Column Setup:Include at least five rows for various aspects with corresponding columns for each subject or category.• Each cell should have a blank space for filling in information derived from the passage.6.Blank Spaces for Answers:• Ensure each blank space is numbered sequentially across the row, following the format in the example.• Precede and follow each blank with a short phrase for context, aiding candidates in understanding what specific detail they need to extract from the passage.7.Key with Correct Answers and Explanations: •After the table, provide a key section where each answer is listed along with a brief explanation of how it is supported by the passage. This section is crucial for educators to understand the rationale behind each correct answer and ensures the test assesses the ability to extract details and understand comparisons or contrasts effectively.",
      submit: "Submit",
      response: "Response",
      responseValue: "",
      loading:false
    },
    {
      id: 7,
      title: "Sentence Endings Question",
      prompt:
        "Generate SENTENCE ENDINGS QUESTIONS TEST for the IELTS Academic Reading section:	Generate six (6) “Sentence Endings” questions, the correct answer and an explanation for the wrong and correct answers.  The questions should adhere to the IELTS Academic Reading Section requirements",
      submit: "Submit",
      response: "Response",
      responseValue: "",
      loading:false
    },
    {
      id: 8,
      title: "Identifying Information Question",
      prompt:
        "Generate IDENTIFYING INFORMATION QUESTIONS TEST for the IELTS Academic Reading section: Generate  six (6) “Identifying Information” questions, the correct answer and an explanation for the wrong and correct answers.  The questions should adhere to the IELTS Academic Reading Section requirements",
      submit: "Submit",
      response: "Response",
      responseValue: "",
      loading:false
    },

    {
      id: 9,
      title: "Matching Heading Question",
      prompt:
        "Generate MATCH HEADINGS QUESTIONS TEST for the IELTS Academic Reading section: 	 Generate six (6) “Matching Headings” questions, the correct answer and an explanation for the wrong and correct answers.  The questions should adhere to the IELTS Academic Reading Section requirements",
      submit: "Submit",
      response: "Response",
      responseValue: "",
      loading:false
    },
    
    {
      id: 10,
      title: "Matching Features Question",
      prompt:
        "Generate MATCHING FEATURES QUESTIONS TEST for the IELTS Academic Reading section:	Generate six (6) “Matching Features” questions, the correct answer and an explanation for the wrong and correct answers.  The questions should adhere to the IELTS Academic Reading Section requirements",
      submit: "Submit",
      response: "Response",
      responseValue: "",
      loading:false
    },
  ];

  constructor() {
    makeAutoObservable(this);
  }
  setPassageValue(value) {
    this.passageValue = value;
  }
}

const generativeAIStore = new GenerativeAiStore();
export default generativeAIStore;
