import React, { useState } from "react";
import CustomTextArea from "../Common/CustomTextArea";
import CustomButton from "../Common/CustomButton";
import generativeAIStore from "../../Services/Stores/generativeAiStore";
import { observer } from "mobx-react-lite";
import htmlDocx from "html-docx-js/dist/html-docx";
import { saveAs } from "file-saver";
import axios from "axios";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";

const GenerativeAi = observer(() => {
  const Store = generativeAIStore;
  const handleSubmit = async (props) => {
    const { prompt, id } = props;
    Store.questionTypes[id - 1].loading = true;
    const payload = { prompt: Store.passageValue + prompt };
    const url =
      "https://staging.api.ielts.cambri.ai/api/generate-chat-gpt-questions";
    const response = await axios.post(url, payload);
    if (response.status === 200) {
      const responseValue = JSON.stringify(response.data?.choices.map(item=>{
        return item.message.content
      }));
      Store.questionTypes[id - 1].responseValue = responseValue;
      Store.questionTypes[id - 1].loading = false;
      toast.success("Submitted Successfully");
    } else {
      toast.error("Something Went Wrong, Please Try Again");
    }
  };

  const handleDownload = () => {
    let download = `<b>Passage:</b><br/><br/> ${Store.passageValue}`;
    Store.questionTypes.forEach((item) => {
      download += `<br/><br/><b>${item.title}:</b><br/><br/><b>Response:</b> ${item.responseValue}`;
    });
    const fileBuffer = htmlDocx.asBlob(download);
    saveAs(fileBuffer, "Reading Passage And Responses.docx");
  };

  return (
    <div>
      <div className="bg-[#e5e7eb] flex gap-10 ">
        <div className="w-[80%] mx-auto min-h-screen mt-28 ml-4 ">
          <div className="m-2">
            <CustomTextArea
              label={"Passage"}
              required={true}
              handleChange={(e) => {
                const value = e.target.value;
                Store.setPassageValue(value);
              }}
              value={Store.passageValue}
              className="text-black"
            />
          </div>
          <div>
            {Store.questionTypes.map((item) => {
              return item.loading === true ? (
                <div className="flex justify-center items-center min-h-screen flex-col">
                  <RotatingLines
                    visible={true}
                    strokeColor="red"
                    height="96"
                    width="96"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                  <h1 className="text-[red]">
                    {" "}
                    Your Prompt is submmiting , Please Wait
                  </h1>
                </div>
              ) : (
                <>
                  <CustomTextArea
                    key={item.id}
                    label={item.title}
                    required={true}
                    value={item.prompt}
                    className="text-black"
                  />
                  <>
                    <div className="flex gap-4 cursor-pointer">
                      <CustomButton
                        classes={"bg-[blue] text-white"}
                        label={item.submit}
                        handleClick={() => handleSubmit(item)}
                        disabled={Store.passageValue === ""}
                      />
                    </div>
                    <div className="m-2">
                      <CustomTextArea
                        label={item.response}
                        required={true}
                        handleChange={(e) => {
                          const response = e.target.value;
                          Store.questionTypes[item.id - 1].responseValue =
                            response;
                        }}
                        value={item.responseValue}
                        className="text-black"
                      />
                    </div>
                  </>
                </>
              );
            })}
          </div>
        </div>
        <div className="mt-28 w-[20%]">
          <CustomButton
            classes={"bg-[blue] text-white"}
            label={"Download"}
            handleClick={handleDownload}
          />
        </div>
      </div>
    </div>
  );
});

export default GenerativeAi;
