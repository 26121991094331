import React, { useEffect, useMemo, useRef, useState } from "react";
import CustomButton from "../Common/CustomButton";
import axios from "axios";
import readingStore from "../../Services/Stores/readingStore";
import { useNavigate, useParams } from "react-router-dom";
import CustomTextField from "../Common/CustomTextField";
import CustomTextArea from "../Common/CustomTextArea";
import { FaPlus, FaTrash } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { observer } from "mobx-react-lite";
import { genericHeaders, serviceUrl } from "../../utils/apiConfig";
import { IoIosArrowForward, IoMdArrowBack } from "react-icons/io";
import CustomToolTip from "../Common/CustomToolTip";
import { toast } from "react-toastify";
import ReadingQuestionTable from "../DynamicTable";
import QuestionTypeModal from "./QuestionTypeModal";
import { toJS } from "mobx";
import OptionComponent from "./OptionComponent";
import IdentifyingInformation from "./IdentifyingInformation";
import { RotatingLines } from "react-loader-spinner";

const Reading = observer(() => {
  const textAreaRef = useRef(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [questionType, setQuestionType] = useState("");
  const [isParagraphUpdated, setIsParagraphUpdated] = useState(false);
  const [loading, setLoading] = useState(false);

  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  const {
    formData,
    sectionId,
    selectedSectionId,
    editMode,
    questionsCategory,
    selectedSectionName,
  } = readingStore;

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const fetchQuestionCategories = async () => {
    try {
      const url = `${serviceUrl}/api/questioncategory/getAll`;
      const response = await axios.get(url, { headers: genericHeaders() });
      readingStore.setQuestionsCategory(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getQuestionById = async () => {
    setLoading(true);
    try {
      const url = `${serviceUrl}/api/questions/getbyId?paragraphId=${id}`;
      const response = await axios.get(url, { headers: genericHeaders() });
      if (response.status === 200) {
        setLoading(false);
        const res = response.data;
        const questionTypesArray = questionsCategory;
        const questionTypeMapping = questionTypesArray.reduce((acc, type) => {
          acc[type._id] = type.name;
          return acc;
        }, {});

        // Replace questionType with name in each question
        const updatedQuestions = res.questions.map((question) => {
          const updatedQuestion = { ...question };
          updatedQuestion.questionType =
            questionTypeMapping[question.questionType];
          return updatedQuestion;
        });

        // Set the updated questions array back to the res object
        const updatedResponse = { ...res, questions: updatedQuestions };
        readingStore.setFormData(updatedResponse);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const deleteQuestion = async (qId, questionId) => {
    if (editMode && questionId) {
      try {
        const url = `${serviceUrl}/api/questions/deletequestion?questionId=${questionId}&passageId=${formData._id}`;
        const response = await axios.delete(url, { headers: genericHeaders() });
        if (response.status === 204) {
          toast.success("Deleted Successfully");
          getQuestionById();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      let updatedQuestions = [...formData.questions];
      updatedQuestions.splice(qId, 1);
      readingStore.setFormData({ ...formData, questions: updatedQuestions });
      toast.success("Deleted Successfully");
    }
  };

  const deleteOption = async (qIndex, qId, optionIndex) => {
    let updatedQuestions = [...formData.questions];
    updatedQuestions[qIndex].answers.splice(optionIndex, 1);
    readingStore.setFormData({ ...formData, questions: updatedQuestions });
    toast.success("Option Deleted Successfully");
  };

  useEffect(() => {
    if (id && questionsCategory && questionsCategory.length > 0) {
      readingStore.setEditMode(true);
      getQuestionById();
    }
  }, [id, questionsCategory]);

  useEffect(() => {
    fetchQuestionCategories();
    return () => {
      readingStore.setEditMode(false);
      setQuestionType("");
      setIsParagraphUpdated(false);
      readingStore.setFormData({
        title: "",
        content: "",
        questions: [],
      });
    };
  }, []);

  const questionTypeMapping = useMemo(() => {
    const mapping = {};
    if (questionsCategory && questionsCategory.length > 0) {
      questionsCategory.forEach((questionType) => {
        mapping[questionType.name] = questionType._id;
      });
    }
    return mapping;
  }, [questionsCategory]);

  const mapFormDataForAPI = (data) => {
    const mappedData = { ...data };
    mappedData["sectionId"] = selectedSectionId;

    mappedData.questions.forEach((question) => {
      question.questionType = questionTypeMapping[question.questionType];
    });
    let globalExtractedCounts = 1;

    mappedData.questions.forEach((question) => {
      let newTable = [];
      if (question?.questionTable && question?.questionTable?.tbody) {
        newTable = question?.questionTable?.tbody?.map((item) => {
          let newItem = { ...item };
          for (let key in newItem) {
            const value = newItem[key];
            const regex = /\{([^}]+)\}/;
            if (value) {
              const match = value.match(regex);
              if (match) {
                const extractedContent = match[1];
                newItem[`${globalExtractedCounts}`] = extractedContent.replace(
                  /[{}]/g,
                  ""
                );
                globalExtractedCounts++;
              }
            }
          }
          return newItem;
        });
      }
      if (question?.questionTable?.tbody) {
        question.questionTable.tbody = newTable;
      }

      const countAnswerPlaceholders = (text) => {
        const regex = /{[^}]*}/g;
        const matches = text.match(regex);
        return matches ? matches.length : 0;
      };

      const countTbodyAnswerPlaceholders = (tbody) => {
        let count = 0;
        if (tbody) {
          tbody.forEach((row) => {
            Object.values(row).forEach((cellValue) => {
              if (typeof cellValue === "string" && /{[^}]*}/.test(cellValue)) {
                count += countAnswerPlaceholders(cellValue);
              }
            });
          });
        }
        return count;
      };

      const questionCount =
        countAnswerPlaceholders(question.question) +
        countTbodyAnswerPlaceholders(question?.questionTable?.tbody);

      question.questionsCount = questionCount > 0 ? questionCount : 1;
    });

    return mappedData;
  };

  const submitandUpdateQuestion = async () => {
    setLoading(true);
    if (sectionId !== null) {
      try {
        let url = `${serviceUrl}/api/`;
        if (editMode) {
          url =
            url +
            `questions/update?paragraphId=${id}&isParagraphUpdated=${isParagraphUpdated}`;
        } else {
          url = url + "questions/create";
        }
        const payload = mapFormDataForAPI(formData);
        const response = await axios[editMode ? "put" : "post"](url, payload, {
          headers: genericHeaders(),
        });
        if (response.status === 201 || response.status === 200) {
          setLoading(false);
          navigate("/dashboard");
          toast.success(
            editMode ? "Updated Sucessfully" : "Submitted Successfully"
          );
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    }
  };

  const createNewQuestion = (questionType) => {
    const newQuestion = {
      get_id: "",
      paragraphId: "",
      question: "",
      explanation: "",
      questionTable: {},
      answers: [],
      questionType: questionType,
    };

    switch (questionType) {
      // case "Note Completion":
      // case "Sentence Completion":
      //   newQuestion.answers = [
      //     {
      //       isActive: true,
      //       answerOption: "",
      //       isCorrectAnswer: false,
      //       answerFieldType: "",
      //     },
      //   ];
      //   break;
      case "Identifying Information (True/False/Not Given)":
        newQuestion.answers = [
          {
            isActive: true,
            answerOption: "True",
            isCorrectAnswer: false,
            answerFieldType: "",
          },
          {
            isActive: true,
            answerOption: "False",
            isCorrectAnswer: false,
            answerFieldType: "",
          },
          {
            isActive: true,
            answerOption: "Not Given",
            isCorrectAnswer: false,
            answerFieldType: "",
          },
        ];
        break;
      default:
        console.warn(`Unexpected questionType: ${questionType}`);
        break;
    }

    return newQuestion;
  };

  const handleQuestionTypeChange = (e) => {
    if (questionType) {
      const updatedQuestions = [...formData.questions];
      const newQuestion = createNewQuestion(questionType);
      updatedQuestions.push(newQuestion);
      readingStore.setFormData({
        ...formData,
        questions: updatedQuestions,
      });
      closeModal();
    }
  };

  const handleAddOption = (questionIndex) => {
    const updatedQuestions = [...formData.questions];
    updatedQuestions[questionIndex].answers.push({
      isActive: true,
      answerOption: "",
      isCorrectAnswer: false,
      answerFieldType: "string",
      correctAnswer: "",
    });
    readingStore.setFormData({ ...formData, questions: updatedQuestions });
  };

  const handleAddPlaceholder = (questionIndex, optionIndex) => {
    const existingAnswerOption = formData.questions[questionIndex].question;
    const inputElement = document.getElementById(
      `optionInput-${questionIndex}-${optionIndex}`
    );
    const cursorPosition = inputElement.selectionStart;

    const newAnswerOption =
      existingAnswerOption.slice(0, cursorPosition) +
      "{answer}" +
      existingAnswerOption.slice(cursorPosition);
    formData.questions[questionIndex].question = newAnswerOption;
  };

  const handleDeletePlaceholder = (questionIndex, optionIndex) => {
    const updatedQuestions = [...formData.questions];
    if (!updatedQuestions[questionIndex].answers) {
      updatedQuestions[questionIndex].answers = [];
    }
    const existingAnswerOption =
      updatedQuestions[questionIndex].answers[optionIndex].answerOption;
    const updatedAnswerOption = existingAnswerOption.replace(/\${\w+}/, "");

    updatedQuestions[questionIndex].answers[optionIndex].answerOption =
      updatedAnswerOption.trim();
    formData.questions = updatedQuestions;
  };

  const handleAddHeading = (questionIndex) => {
    if (textAreaRef.current) {
      const cursorPosition = textAreaRef.current.selectionStart;
      const currentContent = formData.questions[questionIndex].questionPassage;

      const updatedValue =
        currentContent.slice(0, cursorPosition) +
        `{heading}` +
        currentContent.slice(cursorPosition);

      const updatedQuestion = {
        ...formData.questions[questionIndex],
        questionPassage: updatedValue,
      };

      const updatedFormData = [...formData.questions];
      updatedFormData[questionIndex] = updatedQuestion;

      readingStore.setFormData({
        ...formData,
        questions: updatedFormData,
      });
    }
  };

  const handleFormDataChange = (
    e,
    questionIndex,
    optionIndex,
    questionType,
    field
  ) => {
    const updatedQuestions = formData.questions.map((question, i) => ({
      ...question,
      [field]: i === questionIndex ? e.target.value : question[field],
    }));
    if (
      field === "question" ||
      field === "explanation" ||
      field === "questionPassage"
    ) {
      updatedQuestions[questionIndex][field] = e.target.value;
    } else if (field === "answerOption") {
      updatedQuestions[questionIndex].answers[optionIndex][field] =
        e.target.value;
    }

    if (
      questionType !== "Multiple Choice (more than one answer)" &&
      field === "isCorrectAnswer"
    ) {
      updatedQuestions[questionIndex].answers.forEach((option, idx) => {
        if (idx === optionIndex) {
          option.isCorrectAnswer = !option.isCorrectAnswer;
        } else {
          option.isCorrectAnswer = false;
        }
      });
    } else if (
      questionType === "Multiple Choice (more than one answer)" &&
      field === "isCorrectAnswer"
    ) {
      updatedQuestions[questionIndex].answers[optionIndex].isCorrectAnswer =
        !updatedQuestions[questionIndex].answers[optionIndex].isCorrectAnswer;
    }
    readingStore.setFormData({ ...formData, questions: updatedQuestions });
  };
  return (
    <div className="flex flex-col h-full mx-auto w-[80%] mt-10 p-8">
      {loading ? (
        <div className="flex justify-center items-center min-h-screen">
          <RotatingLines
            visible={true}
            strokeColor="red"
            height="96"
            width="96"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <div className="bg-gray-100">
          <div>
            <button
              className="hover:bg-slate-500 hover:text-white rounded-md flex items-center bg-gray-300 py-2 px-6 mb-4"
              onClick={() => navigate("/dashboard")}
            >
              <IoMdArrowBack /> <span className="ml-1">Back</span>
            </button>
          </div>
          <p className="flex items-center mb-4 font-medium text-lg">
            {selectedSectionName} <IoIosArrowForward />{" "}
            {editMode ? "Edit a Passage" : "Create a Passage"}
          </p>
          <CustomTextField
            required={true}
            name="title"
            handleChange={(e) => {
              setIsParagraphUpdated(true);
              readingStore.setFormData({ ...formData, title: e.target.value });
            }}
            label={"Title"}
            value={formData.title}
          />
          <div className="mt-2">
            <CustomTextArea
              questionType={questionType}
              showAddHeadingBtn={false}
              handleAddHeading={handleAddHeading}
              textAreaRef={textAreaRef}
              required={true}
              name="content"
              handleChange={(e) => {
                setIsParagraphUpdated(true);
                readingStore.setFormData({
                  ...formData,
                  content: e.target.value,
                });
              }}
              label={"Paragraph"}
              value={formData.content}
            />
          </div>

          {(questionType || editMode) && (
            <div>
              {formData &&
                formData.questions &&
                formData.questions.length > 0 &&
                formData.questions.map((question, questionIndex) => {
                  const { questionType } = question;
                  let hasPlaceholder = "";
                  if (question.answers && question.answers.length > 0) {
                    hasPlaceholder = /{(\w+)}/.test(question.question);
                  }
                  return (
                    <div
                      key={questionIndex}
                      className="flex flex-col w-full border border-gray-300 p-4 my-4 rounded-md"
                    >
                      <div className="my-2">
                        <div className="flex items-center w-full mb-2">
                          <p className="flex-1 text-gray-900 font-semibold">
                            {questionType}
                          </p>
                          <div className="flex justify-end">
                            <FaTrash
                              data-tooltip-id="delete_question"
                              className="text-[red] cursor-pointer flex justify-end"
                              onClick={() =>
                                deleteQuestion(questionIndex, question._id)
                              }
                            />
                          </div>
                        </div>
                        {questionType === "Matching Headings" && (
                          <div>
                            <CustomTextArea
                              questionType={questionType}
                              showAddHeadingBtn={true}
                              handleAddHeading={handleAddHeading}
                              textAreaRef={textAreaRef}
                              required={true}
                              name="questionPassage"
                              handleChange={(e) => {
                                handleFormDataChange(
                                  e,
                                  questionIndex,
                                  "",
                                  questionType,
                                  "questionPassage"
                                );
                              }}
                              label={"Question Paragraph"}
                              value={question.questionPassage}
                              questionIndex={questionIndex}
                            />
                          </div>
                        )}
                        {questionType === "Matching Features" && (
                          <>
                            <div className="font-bold text-xl">Heading</div>
                            <>
                              <CustomTextField
                                key={questionIndex}
                                handleChange={(e) =>
                                  handleFormDataChange(
                                    e,
                                    questionIndex,
                                    "",
                                    questionType,
                                    "heading"
                                  )
                                }
                                value={
                                  formData.questions[questionIndex].heading
                                }
                                placeholder={"Enter"}
                              />
                            </>
                          </>
                        )}
                        <label className="mb-1 text-[#4E5664] flex items-center justify-between w-full">
                          Question {questionIndex + 1}
                        </label>
                        <CustomToolTip
                          place={"bottom"}
                          content={"Delete Question"}
                          id={"delete_question"}
                        />
                        <div className="flex items-center">
                          <CustomTextField
                            id={`optionInput-${questionIndex}-${0}`}
                            handleChange={(e) => {
                              const inputValue = e.target.value;
                              handleFormDataChange(
                                e,
                                questionIndex,
                                "",
                                questionType,
                                "question"
                              );
                              if (
                                questionType === "Note Completion" ||
                                questionType === "Sentence Completion"
                              ) {
                                const updatedQuestions = [
                                  ...formData.questions,
                                ];
                                const match = e.target.value.match(/{(.*?)}/);
                                // if (match) {
                                //   const extractedValue = match[1];
                                //   updatedQuestions[
                                //     questionIndex
                                //   ].answers[0]['correctAnswer'] = extractedValue;
                                // }
                              }
                            }}
                            value={question.question}
                            placeholder={"Enter"}
                          />
                          {(questionType === "Note Completion" ||
                            questionType === "Sentence Completion" ||
                            questionType === "Sentence Endings" ||
                            questionType ===
                              "Summary Completion (selecting from a list of words or phrases)" ||
                            questionType ===
                              "Summary Completion (selecting word from text)") && (
                            <div className="ml-2 justify-end">
                              {hasPlaceholder &&
                              questionType !== "Sentence Completion" ? (
                                <div data-tooltip-id="my-tooltip-1">
                                  {/* <button
                                className="inline-block ml-2"
                                onClick={() =>
                                  handleDeletePlaceholder(questionIndex, questionIndex)
                                }
                              >
                                <MdOutlineDelete className="text-[red] text-lg" />
                              </button>
                              <Tooltip
                                style={{
                                  color: "black",
                                  background: "#FFEAEA",
                                  borderRadius: "5px",
                                }}
                                id="my-tooltip-2"
                                place="bottom"
                                content="Add Answer"
                              /> */}
                                </div>
                              ) : (
                                <div data-tooltip-id="my-tooltip-2">
                                  <button
                                    className="inline-block ml-2"
                                    onClick={() =>
                                      handleAddPlaceholder(questionIndex, 0)
                                    }
                                  >
                                    <FaPlus />
                                  </button>
                                  <Tooltip
                                    style={{
                                      color: "black",
                                      background: "#FFEAEA",
                                      borderRadius: "5px",
                                    }}
                                    id="my-tooltip-2"
                                    place="bottom"
                                    content="Add Answer"
                                  />
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>

                      {(questionType === "Sentence Endings" ||
                        questionType === "Sentence Completion" ||
                        questionType === "Note Completion" ||
                        questionType ===
                          "Summary Completion (selecting from a list of words or phrases)") &&
                        question.answers.map((option, optionIndex) => (
                          <OptionComponent
                            questionIndex={questionIndex}
                            optionIndex={optionIndex}
                            questionType={questionType}
                            option={option}
                            handleFormDataChange={handleFormDataChange}
                            alphabeticalCount={alphabet[optionIndex]}
                            handleDeleteOption={deleteOption}
                            qId={question._id}
                          />
                        ))}

                      {questionType === "Matching Headings" &&
                        question.answers.map((option, optionIndex) => {
                          const alphabeticalCount = alphabet[optionIndex];
                          return (
                            <div key={optionIndex}>
                              <label className="mr-4 mb-1 text-[#4E5664] flex items-center">
                                Option {alphabeticalCount}{" "}
                                <FaTrash
                                  onClick={() =>
                                    deleteOption(
                                      questionIndex,
                                      question._id,
                                      optionIndex
                                    )
                                  }
                                  className="text-[red] ml-2 cursor-pointer"
                                />
                              </label>
                              <div className="flex items-center my-2 w-full">
                                {/* <input
                                  className="flex-1 py-3 px-4 mr-10 border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                                  type="text"
                                  placeholder="{Heading}"
                                  value={option.answerPlaceHolder}
                                  onChange={(e) => {
                                    const updatedQuestions = [
                                      ...formData.questions,
                                    ];
                                    updatedQuestions[questionIndex].answers[
                                      optionIndex
                                    ].answerPlaceHolder = e.target.value;
                                    readingStore.setFormData({
                                      ...formData,
                                      questions: updatedQuestions,
                                    });
                                  }}
                                /> */}
                                <input
                                  className="flex-1 py-3 px-4 mr-10 border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                                  type="text"
                                  placeholder="Option"
                                  value={option.answerOption}
                                  onChange={(e) =>
                                    handleFormDataChange(
                                      e,
                                      questionIndex,
                                      optionIndex,
                                      questionType,
                                      "answerOption"
                                    )
                                  }
                                />
                                <div className="flex items-center justify-end">
                                  <input
                                    className="mr-2"
                                    type="radio"
                                    name={`option_${questionIndex}`}
                                    checked={option.isCorrectAnswer}
                                    onChange={(e) =>
                                      handleFormDataChange(
                                        e,
                                        questionIndex,
                                        optionIndex,
                                        "Matching Headings",
                                        "isCorrectAnswer"
                                      )
                                    }
                                  />
                                  <label>Correct Answer</label>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                      {questionType ===
                        "Identifying Information (True/False/Not Given)" &&
                        question.answers.map((option, optionIndex) => (
                          <IdentifyingInformation
                            optionIndex={optionIndex}
                            questionIndex={questionIndex}
                            handleFormDataChange={handleFormDataChange}
                            option={option}
                          />
                        ))}
                      {(questionType === "Multiple Choice" ||
                        questionType === "Matching Features") &&
                        question.answers.map((option, optionIndex) => {
                          const alphabeticalCount = alphabet[optionIndex];

                          return (
                            <div key={optionIndex}>
                              <label className="mr-4 mb-1 text-[#4E5664] flex items-center">
                                Option {alphabeticalCount}{" "}
                                <FaTrash
                                  onClick={() => {
                                    deleteOption(
                                      questionIndex,
                                      question._id,
                                      optionIndex
                                    );
                                  }}
                                  className="text-[red] ml-2 cursor-pointer"
                                />
                              </label>
                              <div className="flex items-center my-2 w-full">
                                <input
                                  className="flex-1 py-3 px-4 mr-10 border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                                  type="text"
                                  placeholder="Option"
                                  value={option.answerOption}
                                  onChange={(e) =>
                                    handleFormDataChange(
                                      e,
                                      questionIndex,
                                      optionIndex,
                                      questionType,
                                      "answerOption"
                                    )
                                  }
                                />
                                <div className="flex items-center justify-end">
                                  <input
                                    className="mr-2"
                                    type="radio"
                                    name={`option_${questionIndex}`}
                                    checked={option.isCorrectAnswer}
                                    onChange={(e) => {
                                      handleFormDataChange(
                                        e,
                                        questionIndex,
                                        optionIndex,
                                        "Multiple Choice",
                                        "isCorrectAnswer"
                                      );
                                    }}
                                  />
                                  <label>Correct Answer</label>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                      {questionType ===
                        "Multiple Choice (more than one answer)" &&
                        question.answers.map((option, optionIndex) => {
                          const alphabeticalCount = alphabet[optionIndex];
                          return (
                            <div key={optionIndex}>
                              <div className="flex items-center my-2 w-full">
                                <label className="mr-2 text-[#4E5664] flex items-center">
                                  Option {alphabeticalCount}{" "}
                                  <FaTrash
                                    onClick={() => {
                                      deleteOption(
                                        questionIndex,
                                        question._id,
                                        optionIndex
                                      );
                                    }}
                                    className="text-[red] ml-2 cursor-pointer"
                                  />
                                </label>
                                <input
                                  className="flex-1 py-3 px-4 mr-10 border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                                  type={"text"}
                                  value={option.answerOption}
                                  onChange={(e) =>
                                    handleFormDataChange(
                                      e,
                                      questionIndex,
                                      optionIndex,
                                      questionType,
                                      "answerOption"
                                    )
                                  }
                                />
                                <div className="flex items-center justify-end">
                                  <input
                                    className="mr-2"
                                    type="checkbox"
                                    checked={option.isCorrectAnswer}
                                    onChange={(e) => {
                                      handleFormDataChange(
                                        e,
                                        questionIndex,
                                        optionIndex,
                                        "Multiple Choice (more than one answer)",
                                        "isCorrectAnswer"
                                      );
                                    }}
                                  />
                                  <label>Correct Answer</label>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      {questionType === "Table Completion" && (
                        <ReadingQuestionTable
                          questionType={questionType}
                          questionIndex={questionIndex}
                        />
                      )}

                      {questionType !==
                        "Identifying Information (True/False/Not Given)" &&
                        questionType !== "Table Completion" &&
                        // questionType !== "Note Completion" &&
                        questionType !== "Table Completion" &&
                        questionType !==
                          "Summary Completion (selecting word from text)" && (
                          // questionType !== "Sentence Completion" &&
                          <div>
                            <button
                              className="border border-[#E31837] text-[#E31837] rounded-full my-4 px-4 py-1"
                              onClick={() => handleAddOption(questionIndex)}
                            >
                              Add Option
                            </button>
                          </div>
                        )}
                      <div className="my-2">
                        <label className="mr-2 mb-2 text-[#4E5664]">
                          Explanation
                        </label>
                        <CustomTextField
                          handleChange={(e) =>
                            handleFormDataChange(
                              e,
                              questionIndex,
                              "",
                              questionType,
                              "explanation"
                            )
                          }
                          value={question.explanation}
                          placeholder={"Enter"}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
          <div>
            <button
              disabled={
                !formData.title ||
                formData.title === "" ||
                !formData.content ||
                formData.content === ""
              }
              className={`${
                !formData.title ||
                formData.title === "" ||
                !formData.content ||
                formData.content === ""
                  ? "text-gray-400 border-gray-400 "
                  : "text-[#E31837] border-[#E31837] "
              }border rounded-3xl px-4 py-1 my-4`}
              onClick={openModal}
            >
              Add Question
            </button>
          </div>

          {formData && formData.questions && formData.questions.length > 0 && (
            <div className="pb-4">
              <CustomButton
                disabled={loading}
                classes={"bg-[#E31837] text-white rounded-3xl px-4 py-1 my-4"}
                label={editMode ? "Update" : "Submit"}
                handleClick={submitandUpdateQuestion}
              />
            </div>
          )}
          <QuestionTypeModal
            isOpen={isModalOpen}
            onClose={closeModal}
            onQuestionTypeChange={handleQuestionTypeChange}
            setQuestionType={setQuestionType}
            data={readingStore.questionsCategory}
          />
        </div>
      )}
    </div>
  );
});

export default Reading;
