import React from "react";

const CustomTextField = ({
  label,
  handleChange,
  value,
  placeholder,
  name,
  id,
  required,
}) => {
  return (
    <>
      <label className="text-[#4E5664]">
        {label}
        {required && <span className="text-[red]">*</span>}
      </label>
      <input
        id={id}
        className="py-3 px-4 w-full border bg-white border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
        type="text"
        onChange={handleChange}
        value={value}
        placeholder={placeholder}
        name={name}
      />
    </>
  );
};

export default CustomTextField;
