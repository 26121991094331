import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import { serviceUrl } from "../utils/apiConfig";
import { setToken } from "../Services/token";

const LoginPage = () => {
  const [loginData, setLoginData] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleChangeLoginData = (e) => {
    const { value, name } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const login = async () => {
    setLoading(true);
    try {
      const url = `${serviceUrl}/api/auth/login`;
      const response = await axios.post(url, loginData);
      if (response.status === 200) {
        setLoading(false);
        setToken("token", response.data.token);
        navigate("/dashboard");
        setErrorMessage("");
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage(error.response.data.message);
      console.error("Error fetching data:", error);
    }
  };

  const handleSignin = () => {
    login();
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission
    login();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleFormSubmit(e);
    }
  };

  const disableLoginButton = () => {
    if (loading || !loginData.userName || !loginData.password) {
      return true;
    } else return false;
  };

  return (
    <div
      className="body-bg min-h-screen pt-12 md:pt-20 pb-6 px-2 md:px-0"
      style={{ fontFamily: "Lato, sans-serif" }}
    >
      <header className="max-w-lg mx-auto">
        <h1 className="text-4xl font-bold text-black text-center">IELTS-CMS</h1>
      </header>

      <main className="bg-white max-w-lg mx-auto p-8 md:p-12 my-10 rounded-lg shadow-2xl">
        <section>
          <h3 className="font-bold text-lg">Welcome to IELTS-CMS</h3>
          <p className="text-gray-600 pt-2">Sign in to your account.</p>
        </section>

        <section className="mt-10">
          <form onSubmit={handleFormSubmit} onKeyDown={handleKeyDown}>
            <div className="flex flex-col">
              <div className="mb-6 pt-3 rounded bg-gray-200">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2 ml-3"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  name="userName"
                  value={loginData.email}
                  onChange={handleChangeLoginData}
                  type="text"
                  id="email"
                  className="bg-gray-200  rounded w-full text-gray-700 focus:outline-none border-b-4 border-gray-300 focus:border-purple-600 transition duration-500 px-3 pb-3"
                />
              </div>
              <div className="mb-6 pt-3 rounded bg-gray-200 relative">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2 ml-3"
                  htmlFor="password"
                >
                  Password
                </label>
                <input
                  name="password"
                  value={loginData.password}
                  onChange={handleChangeLoginData}
                  type={showPassword ? "text" : "password"}
                  id="password"
                  className="bg-gray-200 rounded w-full text-gray-700 focus:outline-none border-b-4 border-gray-300 focus:border-purple-600 transition duration-500 px-3 pb-3 pr-10" // Adjusted the paddingRight for the icon
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                  {showPassword ? (
                    <IoIosEye
                      className="text-gray-500 cursor-pointer"
                      onClick={handleTogglePasswordVisibility}
                    />
                  ) : (
                    <IoIosEyeOff
                      className="text-gray-500 cursor-pointer"
                      onClick={handleTogglePasswordVisibility}
                    />
                  )}
                </div>
              </div>
              <p className="text-[red] mb-3">{errorMessage}</p>
              <button
                type="submit"
                disabled={disableLoginButton()}
                onClick={handleSignin}
                className={`${
                  disableLoginButton() && "opacity-50 cursor-not-allowed"
                } bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 rounded shadow-lg hover:shadow-xl transition duration-200`}
              >
                {loading ? "Signing In..." : "Sign In"}
              </button>
              <div className="max-w-lg mx-auto text-center mt-12 mb-4">
                <p className="text-black">
                  Don't have an account?{" "}
                  <Link to="/signup" className="font-bold hover:underline">
                    Sign up
                  </Link>
                  .
                </p>
              </div>
            </div>
          </form>
        </section>
      </main>
    </div>
  );
};

export default LoginPage;
