export const makeid_alpa_numeric = (length) => {
  let result = "";
  const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const upload_file_to_s3 = (
  path,
  file,
  filename,
  unique = false
) => {
  return {location:"https://www.google.com/"} ;
};
